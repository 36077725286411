.home {
	height: 100vh;
	display: grid;
	align-items: center;
	justify-items: center;
}
.join {
	display: flex;
	align-items: center;
}

.content {
	display: grid;
	gap: 1rem;
	padding: 0.5rem 2rem 2rem !important;
	text-align: center;
	justify-items: center;
}
