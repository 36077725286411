.board {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-columns: repeat(6, 1fr);
	height: calc(100vh - 2rem);
	width: calc(100vw - 2rem);
	background-color: #303030;
	color: white;
	grid-gap: 1rem;
	padding: 1rem;
}
