.root {
    background-color: transparent;
    perspective: 1000px;
    user-select: none;
}

.cardInner {
    border-radius: 5px;
    border: 1px solid #111;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.selected {
    transform: rotateX(-180deg);
}

.front,
.back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.front {
    background-color: #404040;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;
}

.title {
    position: absolute;
    bottom: 0;
    text-shadow: 0px 0px 5px #000000;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5vh;
}

.back {
    transform: rotateX(-180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: 600%;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0px 0px 5px black;
    font-size: 3vw;
}

.cardImage {
    max-width: 100%;
    max-height: 100%;
}