body {
	overflow: hidden;
	user-select: none;
}
.game {
	display: grid;
	height: 100vh;
	grid-template-columns: 1fr 20vw;
	@media (max-width: 768px) {
		grid-template-columns: 1fr 15vw;
	}
}
