.player {
	display: grid;
	grid-gap: 0.5rem;
	align-items: start;
	justify-items: center;
	text-align: center;
}
.playerCard {
	width: 80%;
}
.buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
}

.content {
	display: grid;
	gap: 1rem;
	padding: 0.5rem 2rem 2rem !important;
	text-align: center;
	justify-items: center;
}
.playerName {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-size: 1.5vw;
}
